@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.element-to-print {
  page-break-after: always; /* Forces a page break after each element */
  break-after: page; /* For modern browsers */
  height: 1120px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.table-container {
  /* width: 600px; */
}
.avoid-break {
  break-inside: avoid; /* Prevent tables from breaking across pages */
}
.intro {
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
}

.qoute {
  border: 4px solid white;
  background-color: #f5f5ff;
  max-width: 600px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 20px;
  text-align: right;
  padding-left: 20px;
  font-size: 17px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.qoute div {
  font-size: 100px;
  position: absolute;
  top: -105px;
  left: 20px;
}

.outerside_design1 div {
  background: white;
  height: 500px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
  font-weight: bold;
}

.outerside_design1 div div {
  background: #000;
  width: 700px;
  height: 80%;
}
.outerside_design2 img {
  width: 80%;
}
.outerside_design2 div {
  background: #eef0fc;
  /* height: 500px; */
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
  font-weight: bold;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  text-align: right;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid;
}

thead {
  background-color: #9d9dff;
}

thead th {
  padding: 10px;
  font-size: 16px;
  color: black;
}

tbody tr {
  border-bottom: 1px solid #9c9ca8;
}

tbody td {
  padding: 8px;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
}
.tdInput {
  padding: 8px;
  text-align: right;
  font-size: 20px;
  font-weight: 500;
}

tbody tr:nth-child(even) {
  background-color: #e2e2fe;
}

tbody tr:last-child {
  border-bottom: none;
}

tbody td.section {
  background-color: #ece6ff;
  font-weight: bold;
  text-align: right;
  color: #333;
}

tfoot td {
  background-color: #a18ff5;
  padding: 10px;
  font-size: 14px;
  color: #fff;
}
.tdInput {
  padding: 8px;
  text-align: right;
  font-size: 20px;
  font-weight: 500;
}
.white {
  background: white;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.OurPlansGrident {
  background: radial-gradient(
    212.02% 140.14% at -1.75% 131.4%,
    #4dbdf7 26.2%,
    #9a9dfe 70.54%,
    #00fff0 92.88%
  );
}


.menu {
  backdrop-filter: saturate(100%) blur(5px);
  background-image: linear-gradient(to right, #eef0fc, #ffffff58);
}

body {
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
  /* outline: 1px solid red; */
}
.container {
  width: 95%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1240px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate {
  animation: fadeIn 0.5s forwards; /* Adjust duration as needed */
}
